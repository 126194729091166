import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import location1 from '../assets/airports&Trains/Location_1.jpg';
import location2 from '../assets/airports&Trains/Location_3.jpg';
import location3 from '../assets/airports&Trains/Location_11.jpg';
import location4 from '../assets/airports&Trains/Location_9.jpg';
import location5 from '../assets/airports&Trains/Location_8.jpg';
import location6 from '../assets/airports&Trains/Location_14.jpg';
import location7 from '../assets/airports&Trains/Location_15.jpg';
import location8 from '../assets/airports&Trains/Location_16.jpg';
import location9 from '../assets/airports&Trains/Location_18.jpg';
import location10 from '../assets/airports&Trains/Location_20.jpg';
import location11 from '../assets/airports&Trains/Location_23.jpg';
import location12 from '../assets/airports&Trains/Location_21.jpg';
import location13 from '../assets/airports&Trains/Location_26.jpg';
import location14 from '../assets/airports&Trains/Location_30.jpg';
import location15 from '../assets/airports&Trains/Location_31.jpg';
import location16 from '../assets/airports&Trains/Location_13.jpg';
import { Carousel } from 'react-bootstrap';
import '../styles.css';

const LocationsHomePage = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    { id: 1, imageUrl: location1, title: 'VPS' },
    { id: 2, imageUrl: location2, title: 'VPS' },
    { id: 3, imageUrl: location3, title: 'GPT' },
    { id: 4, imageUrl: location4, title: 'GPT' },
    { id: 5, imageUrl: location5, title: 'GPT' },
    { id: 6, imageUrl: location6, title: 'MSO' },
    { id: 7, imageUrl: location7, title: 'MSO' },
    { id: 8, imageUrl: location8, title: 'MSO' },
    { id: 9, imageUrl: location9, title: 'Chicago Train Station' },
    { id: 10, imageUrl: location10, title: 'Chicago Train Station' },
    { id: 11, imageUrl: location11, title: 'CRW' },
    { id: 12, imageUrl: location12, title: 'CRW' },
    { id: 13, imageUrl: location13, title: 'ILM' },
    { id: 14, imageUrl: location14, title: 'VPS' },
    { id: 15, imageUrl: location15, title: 'CRW' },
    { id: 16, imageUrl: location16, title: 'MSO' },
  ];

  const chunkSize = 4;
  const chunkedImages = [];
  for (let i = 0; i < images.length; i += chunkSize) {
    chunkedImages.push(images.slice(i, i + chunkSize));
  }

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="locationimage-carousel-container">
      <Carousel
        controls={false}  // Disable default controls (< and >)
        indicators={false} // Disable default indicators
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={3000}
        wrap={true}
      >
        {chunkedImages.map((chunk, index) => (
          <Carousel.Item key={index}>
            <div className="locationcarousel-image-group">
              {chunk.map((image, subIndex) => (
                <div key={subIndex} className="locationcarousel-card">
                  <img
                    className="locationcarousel-image"
                    src={image.imageUrl}
                    alt={image.title}
                  />
                  <p className="locationcarousel-caption">{image.title}</p>
                </div>
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      {/* Custom indicators */}
      <ul className="custom-carousel-indicators">
        {chunkedImages.map((_, index) => (
          <li
            key={index}
            onClick={() => handleSelect(index)}
            className={activeIndex === index ? 'active' : ''}
          ></li>
        ))}
      </ul>
    </div>
  );
};

export default LocationsHomePage;
